

<template>
    <div>
      <iframe id="iframe1" src="https://admin.evuri.in/cms/LEGAL" width="100%" height="1000" border="2"></iframe>
    </div>
  </template>
  
  <script>
  function setIframeSrc() {
    var s = "path/to/file";
    var iframe1 = document.getElementById('iframe1');
    if ( -1 == navigator.userAgent.indexOf("MSIE") ) {
      iframe1.src = s;
    }
    else {
      iframe1.location = s;
    }
  }
  setTimeout(setIframeSrc, 5);
</script>
  
  <style scoped>
  
  </style>
  